<template>
  <div class="sub-header">
    <div class="sub-header__wrap container flex">
      <h1 class="sub-header__title main-title">
        {{ title }}
      </h1>
      <button class="sub-header__button" @click="$router.go('-1')">Назад</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Редактирование транспорта",
    };
  },
  watch: {
    "$route.params.type"(to) {
      this.changeRout(to);
    },
  },
  mounted() {
    this.changeRout(this.$route.params.type);
  },
  methods: {
    changeRout(n) {
      switch (n) {
        case "new-driver":
          this.title = "Водитель";
          break;
        case "new-expeditor":
          this.title = "Экспедитор";
          break;
        case "new-transport":
          this.title = "Редактирование транспорта";
          break;
        case "new-counterparty":
          this.title = "Контерагент";
          break;
        case "new-carriage":
          this.title = "Перевозка";
          break;
        case "new-manager":
          this.title = "Пользователи";
          break;
        case "new-city":
          this.title = "Города";
          break;
        case "new-region":
          this.title = "Направления";
          break;
        case "new-purchase":
          this.title = "Закуп";
          break;
        case "new-point":
          this.title = "Новый заказ";
          break;
        default:
          this.title = "Редактирование транспорта";
          break;
      }
    },
  },
};
</script>

<style scoped>
.sub-header {
  padding: 20px 0;
  background-color: #fff;
  border: 1px solid #f2f2f8;
  margin-bottom: 20px;
}
.sub-header__wrap {
  height: 44px;
}
.sub-header__title {
  margin: 7px 0;
}
.sub-header__button {
  width: 68px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #707070;
}
</style>
